import { AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FuseUtilsService } from '@fuse/services/utils';
import { createMask } from '@ngneat/input-mask';
import { ExternalMerchantData, Merchant } from 'app/api/generated';
import { MerchantRepresentative } from 'app/api/generated';
import { OnboardingQuery } from 'app/modules/onboarding-management/data-access/onboarding.query';
import { CRUDMode, MerchantRepresantativeRoles } from 'app/shared/enums/enum';
import { peselValidator } from 'app/shared/validators/pesel.validator';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styles: [
    `
      input.disabled {
        cursor: not-allowed;
      }
    `,
  ],
})
export class CompanyComponent implements AfterContentInit, OnChanges, OnDestroy {
  @Input() merchant: Merchant | ExternalMerchantData;
  @Input() mode: CRUDMode;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  @Output() modeChanged: EventEmitter<any> = new EventEmitter<any>();

  public error$ = this.query.error$;
  public loading$ = this.query.loading$;

  form: UntypedFormGroup;

  taxInputMask = createMask('999-999-99-99');
  merchantRepresantativeRoles = MerchantRepresantativeRoles;
  role: string;
  CRUDModeOptions = CRUDMode;

  test = true;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  get addressCtrl() {
    return this.detailsCtrl?.get('address');
  }
  get representativesCtrl() {
    return this.detailsCtrl?.get('representatives') as UntypedFormArray;
  }
  get benefitialOwnersCtrl() {
    return this.detailsCtrl?.get('benefitialOwners') as UntypedFormArray;
  }
  get detailsCtrl() {
    return this.form?.get('details');
  }
  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly query: OnboardingQuery,
    private readonly cdr: ChangeDetectorRef,
    private readonly utilsService: FuseUtilsService,
  ) {}

  ngOnChanges(): void {
    if (this.form) {
      this.mode === CRUDMode.UPDATE ? this.form.enable() : this.form.disable();
      this.cdr.detectChanges();
    }
  }

  ngAfterContentInit(): void {
    if (this.merchant['status']) {
      this.mode = CRUDMode.READ;
      // this.modeChanged.emit(this.mode);
    }

    this.form = this.fb.group({
      taxId: ['', Validators.required],
      nationalRegisterOfCourt: [''],
      details: this.fb.group({
        companyName: [{ value: '', disabled: true }, Validators.required],
        merchandiseType: ['ELECTRONICS', Validators.required],
        address: this.fb.group({
          street: ['', Validators.required],
          streetNo: ['', Validators.required],
          apartmentNo: [''],
          postalCode: ['', Validators.required],
          city: ['', Validators.required],
          country: [{ value: '' }],
        }),
        benefitialOwners: this.fb.array([]),
        representatives: this.fb.array([]),
        websiteUrl: ['https://', [Validators.minLength(13)]],
        bankAccount: [''],
      }),
    });

    this.representativesCtrl.valueChanges
      .pipe(takeUntil(this._unsubscribeAll), distinctUntilChanged(), debounceTime(20))
      .subscribe((representatives: MerchantRepresentative[]) => {
        representatives.forEach((r: MerchantRepresentative, i: number) => {
          if (r.firstName?.length === 1) {
            (this.representativesCtrl as UntypedFormArray)?.controls[i]?.get('firstName').setValue(r.firstName.toUpperCase());
          }
          if (r.lastName?.length === 1) {
            (this.representativesCtrl as UntypedFormArray)?.controls[i]?.get('lastName').setValue(r.lastName.toUpperCase());
          }
        });
      });

    this.initForm();
  }

  initForm(): void {
    if (!this.form) {
      return;
    }

    this.form.patchValue(this.merchant);
    const formRepresentatives = this.representativesCtrl as UntypedFormArray;
    const formBenefitialOwners = this.benefitialOwnersCtrl as UntypedFormArray;

    if (this.merchant['nipLookup']) {
      this.form.patchValue(this.merchant['nipLookup']);
      this.detailsCtrl.patchValue(this.merchant['nipLookup']);
      this.addressCtrl.patchValue(this.merchant['nipLookup']);

      if (this.merchant['nipLookup'].companyType === 'SELF_EMPLOYED') {
        const gus = this.merchant['gusReport'];
        let group = this.getEmptyRepresentativeFormGroup(false);
        const values = {
          firstName: `${gus.fiz_imie1}${gus.fiz_imie2 ? ' ' + gus.fiz_imie2 : ''}`,
          lastName: gus.fiz_nazwisko.join(' '),
          role: 'WŁAŚCICIEL',
          nationality: 'PL',
        };

        group.disable();
        group.patchValue(values);
        group.get('pesel').enable();

        formRepresentatives.push(group);

        let group2 = this.getEmptyRepresentativeFormGroup(true);
        group2.patchValue(values);
        group2.disable();
        group2.get('pesel').enable();
        formBenefitialOwners.push(group2);
      }
    }

    this.form.get('nationalRegisterOfCourt').setValue(this.merchant['vatAccountLookup']?.['krs'] || '');

    // this.detailsCtrl.get('bankAccount').setValue(this.merchant['vatAccountLookup']?.['accountNumbers']);

    if (this.merchant['rejestrIoLookup']?.people.length) {
      this.merchant['rejestrIoLookup'].people.forEach(value => {
        const group = this.getEmptyRepresentativeFormGroup(false);
        group.patchValue(value);
        group.disable();
        group.get('pesel').enable();
        formRepresentatives.push(group);
      });
    }

    if (this.merchant['details']?.representatives.length) {
      this.merchant['details'].representatives.forEach(value => {
        const group = this.getEmptyRepresentativeFormGroup(false);
        group.patchValue(value);
        group.disable();
        group.get('pesel').enable();
        formRepresentatives.push(group);
      });
    }

    if (this.merchant['crbrLookup']?.owners.length) {
      this.merchant['crbrLookup'].owners.forEach(value => {
        const group = this.getEmptyRepresentativeFormGroup(true);
        group.patchValue(value);
        group.disable();
        group.get('pesel').enable();
        formBenefitialOwners.push(group);
      });
    }

    if (this.merchant['details']?.benefitialOwners.length) {
      this.merchant['details']?.benefitialOwners.forEach(value => {
        const group = this.getEmptyRepresentativeFormGroup(true);
        group.patchValue(value);
        group.disable();
        group.get('pesel').enable();
        formBenefitialOwners.push(group);
      });
    }

    if (this.mode === CRUDMode.READ) {
      this.form.disable();
    }
  }

  checkFormValidation() {
    Object.values(this.form.controls).forEach(control => control.markAsTouched());
    Object.values((this.addressCtrl as UntypedFormGroup).controls).forEach(control => control.markAsTouched());
    Object.values((this.detailsCtrl as UntypedFormGroup).controls).forEach(control => control.markAsTouched());
    // Object.values((this.representativesCtrl as FormGroup).controls).forEach((control) => control.markAsTouched());

    (this.representativesCtrl as UntypedFormArray).controls.forEach((el: UntypedFormGroup) => {
      Object.values(el.controls).forEach(control => control.markAsTouched());
    });

    (this.benefitialOwnersCtrl as UntypedFormArray).controls.forEach((el: UntypedFormGroup) => {
      Object.values(el.controls).forEach(control => control.markAsTouched());
    });
  }

  disablePeselValidation(control: UntypedFormControl) {
    if (control.validator !== null) {
      control.clearValidators();
    } else {
      control.setValidators([peselValidator]);
    }
  }

  onSubmit() {
    this.checkFormValidation();
    if (this.form.invalid) {
      return;
    }
    this.changed.emit(this.form.getRawValue() as any);
  }

  getEmptyRepresentativeFormGroup(isBeneficialOwner: boolean = false) {
    return !isBeneficialOwner
      ? this.fb.group({
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          role: ['', Validators.required],
          pesel: ['', [peselValidator]],
          dateOfBirth: [''],
        })
      : this.fb.group({
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          nationality: ['', Validators.required],
          pesel: ['', [peselValidator]],
          dateOfBirth: [''],
        });
  }

  removeRepresentative(i: number) {
    this.representativesCtrl.removeAt(i);
  }

  addRepresentative() {
    this.representativesCtrl.push(this.getEmptyRepresentativeFormGroup());
  }

  removeBeneficialOwner(i: number) {
    this.benefitialOwnersCtrl.removeAt(i);
  }

  addBeneficialOwner() {
    this.benefitialOwnersCtrl.push(this.getEmptyRepresentativeFormGroup(true));
  }

  ngOnDestroy(): void {
    if (this._unsubscribeAll) {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next(true);
      this._unsubscribeAll.complete();
    }
  }
}
