import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
  pure: true,
})
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    if (value.substring(0, 3) === '+48') {
      value = value.substring(3, value.length);
    }
    if (value.length === 9) {
      return value.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
    }
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
  }
}
