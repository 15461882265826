/**
 * AuthInterceptor intercepts outgoing HTTP requests to handle authentication.
 * - Adds Bearer token for authorized requests.
 * - Automatically refreshes expired tokens.
 * - Modifies request configurations for specific API endpoints.
 * - Handles errors such as unauthorized responses.
 */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

const PATH_ALLOWLIST = ['/users/activation', '/login', '/sign-in'];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly TOKEN_REFRESH_THRESHOLD_SECONDS = 300;

  constructor(private authService: AuthService) {}

  /**
   * Determines whether redirection should occur for unauthorized requests.
   * If the URL matches an allowlisted path, redirection is skipped.
   *
   * @param path The URL path of the request.
   * @returns True if redirection should occur, false otherwise.
   */
  private shouldRedirect(path: string): boolean {
    return !PATH_ALLOWLIST.some(partial => path.includes(partial));
  }

  /**
   * Intercepts and processes outgoing HTTP requests.
   * - Adds an authorization token if available and valid.
   * - Refreshes the token if near expiration.
   * - Modifies specific requests based on URL patterns.
   *
   * @param req The outgoing HTTP request.
   * @param next The HTTP handler for the next step in the pipeline.
   * @returns An Observable of the HTTP event.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq = req.clone();
    let authToken = this.authService.getToken();

    // Refresh the token if expired or near expiration
    if (authToken && AuthUtils.isTokenExpired(authToken, this.TOKEN_REFRESH_THRESHOLD_SECONDS) && !req.url.includes('/login')) {
      return from(this.authService.refreshToken()).pipe(
        switchMap((response: { token: string }) => {
          if (response.token) {
            authToken = response.token;
            this.authService.setToken(authToken);
          }
          const clonedRequest = this.cloneRequestWithToken(req, authToken);
          return next.handle(clonedRequest);
        }),
        catchError(error => this.handleError(error, req, next)),
      );
    }

    // Add authorization header if token is valid
    if (authToken && !AuthUtils.isTokenExpired(authToken)) {
      newReq = req.clone({
        headers: new HttpHeaders({
          Authorization: `Bearer ${authToken}`,
        }),
      });
    }

    // Handle specific URL patterns
    newReq = this.processRequest(req, newReq);

    return next.handle(newReq).pipe(catchError(error => this.handleError(error, req, next)));
  }

  /**
   * Clones the HTTP request and adds an authorization header with the token.
   *
   * @param req The original HTTP request.
   * @param token The authentication token to be added.
   * @returns A cloned HTTP request with the Authorization header.
   */
  private cloneRequestWithToken(req: HttpRequest<any>, token: string | null): HttpRequest<any> {
    if (token) {
      return req.clone({
        headers: new HttpHeaders({
          Authorization: `Bearer ${token}`,
        }),
      });
    }
    return req;
  }

  /**
   * Handles HTTP errors encountered during requests.
   * - Signs out and reloads the page for unauthorized errors (401) if redirection is needed.
   *
   * @param error The HTTP error response.
   * @param req The original HTTP request.
   * @param next The HTTP handler for the next step.
   * @returns An Observable of the error.
   */
  private handleError(error: HttpErrorResponse, _req: HttpRequest<any>, _next: HttpHandler): Observable<HttpEvent<any>> {
    if (error instanceof HttpErrorResponse && error.status === 401) {
      this.authService.signOut();
      location.reload();
    }
    return throwError(error?.error || error);
  }

  /**
   * Processes requests with specific URL patterns and modifies their configurations.
   * - Adjusts response types for certain endpoints.
   *
   * @param req The original HTTP request.
   * @param newReq The modified HTTP request.
   * @returns A potentially modified HTTP request.
   */
  private processRequest(req: HttpRequest<any>, newReq: HttpRequest<any>): HttpRequest<any> {
    const patterns = {
      settlements: /\/settlements\/[\S]{0,}\/[\S]{0,}\/[0-9]{4}-[0-9]{2}-[0-9]{2}/,
      invoice: /\/invoicing\/[\S]{0,}\/[\S]{0,}\/[A-Za-z0-9_]{0,}/,
      paymentHistory: /\/order\/[\S]{0,}\/refund\/[\S]{0,}\/pdf/,
      documents: /\/manualOnboarding\/merchants\/[\S]{0,}\/documents\/[\S]{0,}$/,
    };

    if (req.url.includes('/reports/') || patterns.settlements.test(req.url)) {
      return newReq.clone({ responseType: 'text' });
    }

    if (req.method === 'POST' && patterns.documents.test(req.url)) {
      return newReq.clone({ responseType: 'blob' });
    }

    if (patterns.invoice.test(req.url) || patterns.paymentHistory.test(req.url)) {
      return newReq.clone({ responseType: 'blob' });
    }

    return newReq;
  }
}
