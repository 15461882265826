import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { ErrorStateComponent } from './components/error-state/error-state.component';
import { LayoutScaffoldComponent } from './components/layout-scaffold/layout-scaffold.component';
import { EnableControlDirective } from './directives/EnableControlDirective';
import { OaNotificationsComponent } from './notifications/oa-notifications/oa-notifications.component';

@NgModule({
  imports: [CommonModule, MatSidenavModule, MatProgressBarModule, ReactiveFormsModule, MatSnackBarModule, ScrollingModule],
  declarations: [LayoutScaffoldComponent, EmptyStateComponent, ErrorStateComponent, OaNotificationsComponent, EnableControlDirective],
  exports: [LayoutScaffoldComponent, EmptyStateComponent, ErrorStateComponent, OaNotificationsComponent, EnableControlDirective],
})
export class OpenAppModule {}
