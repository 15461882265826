import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OaNotificationsService } from '@openapp';
import { StateManagementService } from '@openapp/state-management/state-management.service';
import {
  AdminOnboardingMerchantRegisterRequest,
  ConsentDefinition,
  ContactsPage,
  CreateCredentialOneTimeResponse,
  CreateCredentialRequest,
  CreateLocationRequest,
  CredentialResponse,
  ExternalMerchantData,
  FeeScheduleCreate,
  FeeScheduleUpdate,
  IntegrationProfileResponse,
  IntegrationProfilesService,
  Location,
  LocationsService,
  ManualOnboardingService,
  Merchant,
  MerchantContractPaymentPage,
  MerchantOnboardingsService,
  MerchantStatusUpdate,
  MerchantWalletsService,
  Paginated_MerchantUser_,
  ReturnLocation,
  TcPp,
  UpdateLocationRequest,
} from 'app/api/generated';
import { MerchantStatus } from 'app/api/generated/models/MerchantStatus';
import { OnboardingQuery } from 'app/modules/onboarding-management/data-access/onboarding.query';
import { OnboardingState, OnboardingStore } from 'app/modules/onboarding-management/data-access/onboarding.store';
import { IMerchantWalletDetails } from 'app/shared/interfaces/merchant-wallet-details.interface';
import { QueryParams } from 'app/shared/interfaces/query-params.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OnboardingManagementService extends StateManagementService<OnboardingState> {
  integrationProfilesSource$: BehaviorSubject<IntegrationProfileResponse[]> = new BehaviorSubject<IntegrationProfileResponse[]>([]);

  constructor(
    protected store: OnboardingStore,
    private readonly backend: MerchantOnboardingsService,
    private readonly manualOnboardingService: ManualOnboardingService,
    private readonly integrationProfilesService: IntegrationProfilesService,
    private readonly locationsService: LocationsService,
    private readonly notifications: OaNotificationsService,
    private readonly query: OnboardingQuery,
    private readonly merchantWalletService: MerchantWalletsService,
    private http: HttpClient,
  ) {
    super(store);
    this.store.setLoading(false);
    this.store.setError(null);
  }

  async getOnboardings(status: MerchantStatus, params: QueryParams) {
    await this.execute(this.manualOnboardingService.listMerchants(status, params.last, params.size), async data => {
      const { values, last } = data;
      this.store.update({ last });
      this.store.set(values);
    });
  }

  async getMerchantByTaxIdPromise(taxId: string): Promise<Merchant> {
    this.store.setLoading(true);
    this.store.setError(null);
    return await this.execute(
      this.manualOnboardingService.getMerchantByTaxId(taxId),
      async data => {
        this.store.set([data]);
      },
      () => {
        this.store.set([]);
      },
    );
  }

  getMerchantContract(merchantId: string): Observable<MerchantContractPaymentPage> {
    return this.manualOnboardingService.getContractPaymentPage(merchantId);
  }

  getFeeSchedule(merchantId: string): Observable<any> {
    return this.manualOnboardingService.listFeeSchedules(merchantId);
  }

  getTcPp(merchantId: string): Observable<TcPp> {
    this.loading(true);
    return this.manualOnboardingService.getTcPp(merchantId).pipe(tap(() => this.loading(false)));
  }

  setTcPp(merchantId: string, requestBody: TcPp): Observable<any> {
    this.loading(true);
    return this.manualOnboardingService.setTcPp(merchantId, requestBody).pipe(tap(() => this.loading(false)));
  }

  getConsentsList(integrationProfileId: string): Observable<ConsentDefinition[]> {
    this.loading(true);
    return this.manualOnboardingService.list(integrationProfileId).pipe(tap(() => this.loading(false)));
  }

  removeConsent(merchantId: string, consentId: string): Observable<void> {
    this.loading(true);
    return this.manualOnboardingService.remove(merchantId, consentId).pipe(tap(() => this.loading(false)));
  }

  setConsent(merchantId: string, consent: ConsentDefinition): Observable<ConsentDefinition> {
    this.loading(true);
    return this.manualOnboardingService.create(merchantId, consent).pipe(tap(() => this.loading(false)));
  }

  updateConsent(merchantId: string, consentId: string, consent: ConsentDefinition): Observable<ConsentDefinition> {
    this.loading(true);
    return this.manualOnboardingService.update(merchantId, consentId, consent).pipe(tap(() => this.loading(false)));
  }

  getContact(merchantId: string): Observable<ContactsPage> {
    this.loading(true);
    return this.manualOnboardingService.getContactPage(merchantId).pipe(tap(() => this.loading(false)));
  }

  setContact(merchantId: string, contactsPage: ContactsPage): Observable<void> {
    this.loading(true);
    return this.manualOnboardingService.setContactPage(merchantId, contactsPage).pipe(tap(() => this.loading(false)));
  }

  getMerchantUsers(merchantId: string, last?: string): Observable<Paginated_MerchantUser_> {
    return this.manualOnboardingService.listUsers(merchantId, last);
  }

  createMerchantUser(merchantId: string, requestBody: any): Observable<any> {
    return this.manualOnboardingService.createUser(merchantId, requestBody);
  }

  updateMerchantUser(merchantId: string, merchantUserId: string, requestBody: any): Observable<any> {
    return this.manualOnboardingService.editUser(merchantId, merchantUserId, requestBody);
  }

  removeMerchantUser(merchantId: string, merchantUserId: string): Observable<void> {
    this.loading(true);
    return this.manualOnboardingService.deleteUser(merchantId, merchantUserId).pipe(tap(() => this.loading(false)));
  }

  getIntegrationProfile(merchantId: string): Observable<IntegrationProfileResponse[]> {
    this.loading(true);
    return this.integrationProfilesService.listIntegrationProfiles(merchantId).pipe(
      tap((profiles: IntegrationProfileResponse[]) => this.integrationProfilesSource$.next(profiles)),
      tap(() => this.loading(false)),
    );
  }

  createIntegrationProfile(merchantId: string, requestBody: any): Observable<any> {
    return this.integrationProfilesService.createIntegrationProfile(merchantId, requestBody);
  }

  updateIntegrationProfile(merchantId: string, integrationId: string, requestBody: any): Observable<any> {
    return this.integrationProfilesService.updateIntegrationProfile(merchantId, integrationId, requestBody);
  }

  createFeeSchedule(merchantId: string, feeSchedule: FeeScheduleCreate): Observable<any> {
    this.loading(true);
    return this.manualOnboardingService.createFeeSchedule(merchantId, feeSchedule).pipe(tap(() => this.loading(false)));
  }

  setMerchantStatus(merchantId: string, reqBody: MerchantStatusUpdate): Observable<void> {
    return this.manualOnboardingService.setMerchantStatusV2(merchantId, reqBody);
  }

  getReturnLocation(merchantId: string): Observable<ReturnLocation> {
    return this.manualOnboardingService.getReturnLocation(merchantId);
  }

  setReturnLocation(merchantId: string, reqBody: ReturnLocation): Observable<void> {
    this.loading(true);
    return this.manualOnboardingService.saveReturnLocation(merchantId, reqBody).pipe(tap(() => this.loading(false)));
  }

  downloadFile(merchantId: string, filePath: string): Observable<any> {
    this.loading(true);
    return this.manualOnboardingService.downloadDocument(merchantId, filePath).pipe(tap(() => this.loading(false)));
  }

  updateFeeSchedule(merchantId: string, serviceId: string, startDate: string, feeSchedule: FeeScheduleUpdate): Observable<any> {
    this.loading(true);
    return this.manualOnboardingService
      .updateFeeSchedule(merchantId, serviceId, startDate, feeSchedule)
      .pipe(tap(() => this.loading(false)));
  }

  getLocations(merchantId: string): Observable<Location[]> {
    return this.locationsService.listMerchantLocations(merchantId).pipe(tap(() => this.loading(false)));
  }

  createLocations(merchantId: string, requestBody: CreateLocationRequest): Observable<void> {
    return this.locationsService.create(merchantId, requestBody).pipe(tap(() => this.loading(false)));
  }

  updateLocations(merchantId: string, id: string, requestBody: UpdateLocationRequest): Observable<void> {
    return this.locationsService.update(merchantId, id, requestBody).pipe(tap(() => this.loading(false)));
  }

  removeLocations(merchantId: string, id: string): Observable<void> {
    return this.locationsService.delete(merchantId, id).pipe(tap(() => this.loading(false)));
  }

  async createCredential(profile: IntegrationProfileResponse, create: CreateCredentialRequest): Promise<any> {
    let credential: CreateCredentialOneTimeResponse;

    const createCopy = { ...create };
    if (createCopy.expiryAt === '') {
      delete createCopy.expiryAt;
    }

    await this.execute(
      await this.integrationProfilesService.createCredential(profile.merchantId, profile.integrationProfileId, createCopy),
      async cred => {
        credential = cred;
      },
      () => {
        this.notifications.push('Error creating profile');
      },
    );
    return credential;
  }

  async deleteCredential(profile: IntegrationProfileResponse, credential: CredentialResponse) {
    await this.execute(
      this.integrationProfilesService.deleteCredential(profile.merchantId, profile.integrationProfileId, credential.credentialId),
      async () => {},
      () => {
        this.notifications.push('Error deleting credential');
      },
    );
  }

  loading(value: boolean) {
    this.store.setLoading(value);
    if (value) {
      this.store.setError(null);
    }
  }

  getMerchantByTaxId(taxId: string): Observable<Merchant> {
    this.store.setLoading(true);
    this.store.setError(null);
    return this.manualOnboardingService.getMerchantByTaxId(taxId).pipe(
      catchError(err => {
        this.store.setLoading(false);
        throw err;
      }),
      tap(data => {
        this.store.setLoading(false);
        this.select(data);
      }),
    );
  }

  getExternalMerchantData(taxId: string): Observable<ExternalMerchantData> {
    this.store.setLoading(true);
    this.store.setError(null);
    return this.manualOnboardingService.getExternalMerchantData(taxId).pipe(
      catchError(err => {
        this.store.setLoading(false);
        throw err;
      }),
      tap(() => this.store.setLoading(false)),
    );
  }

  registerMerchant(merchant: AdminOnboardingMerchantRegisterRequest): Observable<AdminOnboardingMerchantRegisterRequest> {
    this.store.setLoading(true);
    this.store.setError(null);

    return this.manualOnboardingService.createMerchant(merchant).pipe(
      catchError(err => {
        this.store.setLoading(false);
        this.store.setError(err);
        throw err;
      }),
      tap(() => this.store.setLoading(false)),
    );
  }

  saveMerchantContract(merchantId: string, contract: any): Observable<any> {
    this.store.setLoading(true);
    this.store.setError(null);

    return this.manualOnboardingService.setContractPaymentPage(merchantId, contract).pipe(
      catchError(err => {
        this.store.setLoading(false);
        this.store.setError(err);
        throw err;
      }),
      tap(() => this.store.setLoading(false)),
    );
  }

  getDocuments(merchantId: string): Observable<string[]> {
    this.store.setLoading(true);
    this.store.setError(null);

    return this.manualOnboardingService.listUploadedDocuments(merchantId).pipe(
      catchError(err => {
        this.store.setLoading(false);
        this.store.setError(err);
        throw err;
      }),
      tap(() => this.store.setLoading(false)),
    );
  }

  uploadDocument(merchantId: string, file): Observable<any> {
    this.store.setLoading(true);
    this.store.setError(null);
    return this.manualOnboardingService.uploadDocument(merchantId, { file: file }).pipe(
      catchError(err => {
        this.store.setLoading(false);
        this.store.setError(err);
        throw err;
      }),
      tap(() => this.store.setLoading(false)),
    );
  }

  async loadMore(status: MerchantStatus, params: QueryParams) {
    await this.execute(
      this.query.last$.pipe(switchMap(last => this.manualOnboardingService.listMerchants(status, last, params.size))),
      async data => {
        const { values, last } = data;
        this.store.update({ last });
        this.store.upsertMany(values);
      },
    );
  }

  uploadFile(onboardingId: string, upload: { file: File }) {
    return this.backend.uploadFile(onboardingId, upload);
  }

  select(merchant: Merchant) {
    this.store.update({ selected: merchant });
  }

  addFileToOnboarding() {
    // this.store.update(onboarding.merchantOnboardId, {
    //   uploadedFiles: [...onboarding.uploadedFiles, file],
    // });
  }

  /*
   *  Method: getMerchantWalletDetails(merchantId: string, profileId: string)
   *
   *  Description:
   *  The method `getMerchantWalletDetails` is used to retrieve detailed information about a merchant's wallet based on the merchant ID
   *  and integration profile ID.
   *
   *  Parameters:
   *  - merchantId (string): The merchant's identifier.
   *  - profileId (string): The integration profile's identifier.
   *
   *  Return Type:
   *  Observable<IMerchantWalletDetails>: An observable stream of data containing detailed information about the merchant's wallet.
   *
   *  Functionality:
   *  The method calls the `merchantWalletService.getWalletDetails(merchantId, profileId)` service to fetch
   *  the merchant's wallet information. After receiving the data, it triggers the `loading(false)` function to indicate
   *  the end of the loading process.
   *
   */
  getMerchantWalletDetails(merchantId: string, profileId: string): Observable<IMerchantWalletDetails> {
    return this.merchantWalletService.getWalletDetails(merchantId, profileId).pipe(tap(() => this.loading(false)));
  }
}
