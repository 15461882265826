import { UntypedFormControl } from '@angular/forms';

export function peselValidator(control: UntypedFormControl) {
  const pesel = control.value.toString();
  if (!pesel) {
    return null;
  }
  const weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  let sum = 0;
  let controlNumber = parseInt(pesel.substring(10, 11));

  for (let i = 0; i < weight.length; i++) {
    sum += parseInt(pesel.substring(i, i + 1)) * weight[i];
  }
  sum = sum % 10;

  if ((10 - sum) % 10 !== controlNumber) {
    return {
      pesel: {
        incorrectCheckSum: true,
      },
    };
  }
  return null;
}
