<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<app-empty-layout *ngIf="layout === 'empty'"></app-empty-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with horizontal navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Centered -->
<app-centered-layout *ngIf="layout === 'centered'"></app-centered-layout>

<!-- Enterprise -->
<app-enterprise-layout *ngIf="layout === 'enterprise'"></app-enterprise-layout>

<!-- Material -->
<app-material-layout *ngIf="layout === 'material'"></app-material-layout>

<!-- Modern -->
<app-modern-layout *ngIf="layout === 'modern'"></app-modern-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Classic -->
<app-classic-layout *ngIf="layout === 'classic'"></app-classic-layout>

<!-- Classy -->
<app-classy-layout *ngIf="layout === 'classy'"></app-classy-layout>

<!-- Compact -->
<app-compact-layout *ngIf="layout === 'compact'"></app-compact-layout>

<!-- Dense -->
<app-dense-layout *ngIf="layout === 'dense'"></app-dense-layout>

<!-- Futuristic -->
<app-futuristic-layout *ngIf="layout === 'futuristic'"></app-futuristic-layout>

<!-- Thin -->
<app-thin-layout *ngIf="layout === 'thin'"></app-thin-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Settings drawer - Remove this to remove the drawer and its button -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- <settings></settings> -->
