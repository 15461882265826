import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormatPipe',
  pure: true,
})
export class CurrencyFormatPipe implements PipeTransform {
  transform(value: { amount: number; currency: string }, withCurrency = false): string {
    return `${formatNumber(value.amount, 'fr-FR', '.2')} ${withCurrency ? value.currency : ''}`;
  }
}
