import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { SettingsComponent } from 'app/layout/common/settings/settings.component';

@NgModule({
  declarations: [SettingsComponent],
  imports: [CommonModule, RouterModule, MatIconModule, MatTooltipModule, FuseDrawerModule, MatButtonModule],
  exports: [SettingsComponent],
})
export class SettingsModule {}
