import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IntegrationProfileResponse } from 'app/api/generated';
import { OnboardingManagementService } from 'app/modules/onboarding-management/data-access/onboarding-management.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-select-integration-profile',
  templateUrl: './select-integration-profile.component.html',
})
export class SelectIntegrationProfileComponent implements OnInit {
  @Input() merchantId: string;
  @Output() profileChanged = new EventEmitter<string>();
  @Output() profileNameChanged = new EventEmitter<string>();
  @Output() selectedMerchantId = new EventEmitter<string>();

  private readonly _lsProfileIdKey = 'selectedProfileId';
  public profiles$: Observable<IntegrationProfileResponse[]>;
  public profileId: string;

  constructor(private readonly onboardingManagementService: OnboardingManagementService) {}

  /**
   * Initializes component and loads integration profiles.
   * If profiles exist in the service cache and match the merchantId, use cached data.
   * Otherwise, fetch profiles from the backend.
   */
  ngOnInit(): void {
    const cachedProfiles = this.onboardingManagementService.integrationProfilesSource$.value;

    if (!cachedProfiles || !cachedProfiles.length || this.merchantId !== cachedProfiles[0].merchantId) {
      this.profiles$ = this.onboardingManagementService.getIntegrationProfile(this.merchantId).pipe(tap(this.processProfiles));
    } else {
      this.profiles$ = this.onboardingManagementService.integrationProfilesSource$.pipe(tap(this.processProfiles));
    }
  }

  /**
   * Processes retrieved integration profiles.
   * Loads selected profile from localStorage or defaults to the first available profile.
   * Emits events with selected profile details.
   * @param profiles List of integration profiles.
   */
  private processProfiles = (profiles: IntegrationProfileResponse[]): void => {
    if (profiles.length && !this.profileId) {
      const storedProfileId = localStorage.getItem(this._lsProfileIdKey);
      const existingProfile = profiles.find(profile => profile.integrationProfileId === storedProfileId);

      this.profileId = existingProfile ? storedProfileId : profiles[0].integrationProfileId;
      const profileName = existingProfile ? existingProfile.integrationProfileName : profiles[0].integrationProfileName;
      const merchantId = existingProfile ? existingProfile.merchantId : profiles[0].merchantId;

      localStorage.setItem(this._lsProfileIdKey, this.profileId);
      this.profileChanged.emit(this.profileId);
      this.profileNameChanged.emit(profileName);
      this.selectedMerchantId.emit(merchantId);
    }
  };

  /**
   * Handles profile selection change.
   * Updates localStorage and emits profile change events.
   * @param profiles List of integration profiles.
   */
  public changedProfile(profiles: IntegrationProfileResponse[]): void {
    const selectedProfile = profiles.find(profile => profile.integrationProfileId === this.profileId);
    if (selectedProfile) {
      localStorage.setItem(this._lsProfileIdKey, this.profileId);
      this.profileNameChanged.emit(selectedProfile.integrationProfileName);
      this.profileChanged.emit(this.profileId);
    }
  }
}
