import { Injectable } from '@angular/core';
import { LoginRequestBody, LoginService, OauthRequestBody } from 'app/api/generated';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { EMPTY, Observable, of } from 'rxjs';
import { map, pluck, tap } from 'rxjs/operators';

/**
 * Interface representing an authentication response containing a token.
 */
interface AuthResponse {
  token: string;
}

const ROOT_PRIVILEGE = 'ROOT';

@Injectable()
export class AuthService {
  constructor(
    private readonly loginService: LoginService,
    private readonly userService: UserService,
  ) {}

  /**
   * Retrieves the authentication token from local storage.
   * @returns {string | null} The stored token or null if not found.
   */
  getToken(): string | null {
    return localStorage.getItem('accessToken');
  }

  /**
   * Stores the authentication token in local storage.
   * @param {string} token - The authentication token to be stored.
   */
  setToken(token: string): void {
    localStorage.setItem('accessToken', token);
  }

  /**
   * Retrieves the OAuth session ID from local storage.
   * @returns {string | null} The stored OAuth session ID or null if not found.
   */
  getOAuthSessionId(): string | null {
    return localStorage.getItem('OAuthSessionId');
  }

  /**
   * Stores the OAuth session ID in local storage.
   * @param {string} sessionId - The OAuth session ID to be stored.
   */
  setOAuthSessionId(sessionId: string): void {
    localStorage.setItem('OAuthSessionId', sessionId);
  }

  /**
   * Performs user sign-in with provided credentials.
   * @param {LoginRequestBody} credentials - User login credentials.
   * @returns {Observable<AuthResponse>} An observable containing the authentication response.
   */
  signIn(credentials: LoginRequestBody): Observable<AuthResponse> {
    return this.loginService.login(credentials).pipe(
      tap((response: AuthResponse) => {
        this.setToken(response.token);
      }),
    );
  }

  /**
   * Signs out the user by removing the authentication token.
   * @returns {Observable<never>} An observable indicating completion.
   */
  signOut(): Observable<never> {
    localStorage.removeItem('accessToken');
    return EMPTY;
  }

  /**
   * Initiates sign-in using Google authentication.
   * @returns {Observable<{ sessionId: string; authUrl: string; }>} An observable containing session ID and authentication URL.
   */
  signInWithGoogleInit(): Observable<{ sessionId: string; authUrl: string }> {
    return this.loginService.oAuthInit().pipe(
      map(response => ({
        sessionId: response.sessionId,
        authUrl: response.authUrl,
      })),
    );
  }

  /**
   * Refreshes the authentication token.
   * @returns {Observable<AuthResponse>} An observable containing the refreshed authentication response.
   */
  refreshToken(): Observable<AuthResponse> {
    return this.loginService.refresh();
  }

  /**
   * Logs in via an external provider using OAuth.
   * @param {OauthRequestBody} oauthRequestBody - OAuth request payload.
   * @returns {Observable<AuthResponse>} An observable containing the authentication response.
   */
  loginViaExternalProvider(oauthRequestBody: OauthRequestBody): Observable<AuthResponse> {
    return this.loginService.oAuthAuth(oauthRequestBody).pipe(
      tap((response: AuthResponse) => {
        this.setToken(response.token);
      }),
    );
  }

  /**
   * Checks whether the user is authenticated.
   * @returns {Observable<boolean>} An observable indicating authentication status.
   */
  isAuthenticated(): Observable<boolean> {
    const token = this.getToken();
    return of(!!token && !AuthUtils.isTokenExpired(token));
  }

  /**
   * Checks if the user has the required permission.
   * @param {string} permission - The required permission.
   * @returns {Observable<boolean>} An observable indicating authorization status.
   */
  isAuthorized(permission: string): Observable<boolean> {
    return this.userService.user$.pipe(
      pluck('privileges'),
      map((privileges: string[]) => privileges.includes(permission) || privileges.includes(ROOT_PRIVILEGE)),
    );
  }
}
