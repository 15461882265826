import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taxPipe',
  pure: true,
})
export class TaxPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1-$2-$3-$4');
  }
}
