import { Injectable } from '@angular/core';
import { StateManagementService } from '@openapp/state-management/state-management.service';
import { AppWalletTrx, AppusersService } from 'app/api/generated';
import { environment } from 'environments/environment';

import { WalletsTransactionsState, WalletsTransactionsStore } from './wallets-transations.store';

@Injectable({
  providedIn: 'root',
})
export class WalletTransactionsService extends StateManagementService<WalletsTransactionsState> {
  last?: string = undefined;
  pagination = environment.pagination;

  constructor(
    protected readonly store: WalletsTransactionsStore,
    private readonly backendAppUser: AppusersService,
  ) {
    super(store);
    this.store.setLoading(false);
    this.store.setError(null);
  }

  async listWalletTransactions(userId: string, walletId: number, reason: string) {
    await this.execute(
      this.backendAppUser.listWalletTransactions(userId, walletId, { reason: reason }, environment.pagination.size, this.last),
      async data => {
        const { values, last } = data;
        this.last = last;
        this.store.add(values);
      },
    );
  }

  selectActive(purchase: AppWalletTrx) {
    this.store.update({ selected: purchase });
  }

  removeStoreResults() {
    this.store.set([]);
    this.last = undefined;
  }
}
