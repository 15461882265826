import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'app/api/generated';
import { AdminUser_User } from 'app/modules/users/data-access/user';
import { Observable, ReplaySubject, of, race } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: ReplaySubject<AdminUser_User> = new ReplaySubject<AdminUser_User>(1);

  constructor(
    private _httpClient: HttpClient,
    private readonly usersService: UsersService,
    private readonly router: Router,
  ) {}

  set user(value: AdminUser_User) {
    this._user.next(value);
  }

  get user$(): Observable<AdminUser_User> {
    return race([this._user.asObservable(), this.get()]);
  }

  get(): Observable<AdminUser_User> {
    return this.usersService.whoAmI().pipe(
      catchError(() => {
        localStorage.removeItem('accessToken');
        this.router.navigate(['sign-in']);
        throw of();
      }),
      tap(user => {
        this._user.next(user);
      }),
    );
  }

  update(user: AdminUser_User): Observable<any> {
    return this._httpClient.patch<AdminUser_User>('api/common/user', { user }).pipe(
      map(response => {
        this._user.next(response);
      }),
    );
  }
}
