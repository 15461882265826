<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <mat-drawer-container class="flex-auto h-full">
    <!-- DRAWERS -->
    <mat-drawer
      class="w-1/2 dark:bg-gray-900"
      [autoFocus]="false"
      [mode]="'over'"
      [position]="'start'"
      [(opened)]="leftDrawerOpened"
      (closed)="leftDrawerOpenedChange.emit(false)"
    >
      <ng-content select="[leftDrawer]"></ng-content>
    </mat-drawer>
    <mat-drawer
      class="w-1/2 dark:bg-gray-900"
      [autoFocus]="false"
      [mode]="'over'"
      [position]="'end'"
      [(opened)]="rightDrawerOpened"
      (closed)="rightDrawerOpenedChange.emit(false)"
    >
      <ng-content select="[rightDrawer]"></ng-content>
    </mat-drawer>
    <mat-drawer-content class="flex flex-col">
      <div
        class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 pb-16 sm:pt-6 sm:pb-20 sm:px-10 bg-default dark:bg-transparent"
      >
        <div class="flex flex-1 items-center min-w-0 h-20">
          <div class="">
            <h2 class="text-2xl xl:text-2xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
              {{ pageTitle }}
            </h2>
          </div>
        </div>
        <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
          <!-- BUTTONS -->
          <ng-content select="[buttons]"></ng-content>
        </div>
      </div>

      <div class="{{ class }} flex-auto -mt-16 rounded-2xl shadow overflow-y-auto bg-card m-10" cdkScrollable>
        <div class="rounded-2xl">
          <!-- CONTENT -->
          <ng-content select="[content]"></ng-content>
          <oa-empty-state *ngIf="emptyState"></oa-empty-state>
          <oa-error-state *ngIf="errorState" [message]="errorMsg"></oa-error-state>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
