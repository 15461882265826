/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { Paginated_SanctionListScanReport_ } from '../models/Paginated_SanctionListScanReport_';
import type { SanctionListScanReport } from '../models/SanctionListScanReport';
import type { SanctionPhysicalEntitySearch } from '../models/SanctionPhysicalEntitySearch';
import type { SanctionSearchResult_SanctionPhysicalEntity_ } from '../models/SanctionSearchResult_SanctionPhysicalEntity_';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class SanctionListService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @returns void
     * @throws ApiError
     */
    public reload(): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/sanctionList/reload',
        });
    }

    /**
     * @param requestBody
     * @returns SanctionSearchResult_SanctionPhysicalEntity_ Ok
     * @throws ApiError
     */
    public search(
        requestBody: SanctionPhysicalEntitySearch,
    ): Observable<SanctionSearchResult_SanctionPhysicalEntity_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/sanctionList/search',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteIndex(): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/sanctionList/index',
        });
    }

    /**
     * @returns SanctionListScanReport Ok
     * @throws ApiError
     */
    public conductGlobalUserScan(): Observable<SanctionListScanReport> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/sanctionList/scan/users/execute',
        });
    }

    /**
     * @param last
     * @param limit
     * @returns Paginated_SanctionListScanReport_ Ok
     * @throws ApiError
     */
    public getUserScanReports(
        last?: EncryptedLastItem,
        limit: number = 20,
    ): Observable<Paginated_SanctionListScanReport_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sanctionList/scan/users/reports',
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

}