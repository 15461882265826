<ng-container *transloco="let t; prefix: 'GENERAL'">
  <ng-container *ngIf="profiles$ | async as profiles">
    <mat-form-field *ngIf="profiles.length > 1" class="w-full sm:w-72 mt-4 sm:mt-0 sm:ml-4">
      <mat-label class="text-md">{{ t('Search by profile name') }}:</mat-label>
      <mat-select [(ngModel)]="profileId" (ngModelChange)="changedProfile(profiles)">
        <mat-option *ngFor="let profile of profiles" [value]="profile.integrationProfileId">
          {{ profile.integrationProfileName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</ng-container>
