import { CommonModule } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localePL from '@angular/common/locales/pl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { CompanyComponent } from 'app/modules/merchants/merchants-details/company/company.component';

import { SelectIntegrationProfileComponent } from './components/select-integration-profile/select-integration-profile.component';
import { DetectScrollToEndDirective } from './directives/detect-scroll-to-end-directive';
import { AddressFormatPipe } from './pipes/address-format.pipe';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { IbanPipe } from './pipes/iban-format.pipe';
import { PhonePipe } from './pipes/phone-format.pipe';
import { PolishDatePipe } from './pipes/polish-date.pipe';
import { RepresentativesFormatPipe } from './pipes/representantive.pipe';
import { TaxPipe } from './pipes/tax-format.pipe';

registerLocaleData(localeFr);
registerLocaleData(localePL);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    TranslocoCoreModule,
    MatDialogModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    TranslocoCoreModule,
    MatDialogModule,
    CompanyComponent,
    SelectIntegrationProfileComponent,
    DetectScrollToEndDirective,
    CurrencyFormatPipe,
    AddressFormatPipe,
    PhonePipe,
    TaxPipe,
    RepresentativesFormatPipe,
    IbanPipe,
    PolishDatePipe,
  ],
  declarations: [
    CurrencyFormatPipe,
    AddressFormatPipe,
    PhonePipe,
    TaxPipe,
    RepresentativesFormatPipe,
    IbanPipe,
    PolishDatePipe,
    CompanyComponent,
    SelectIntegrationProfileComponent,
    DetectScrollToEndDirective,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: LOCALE_ID, useValue: 'pl-PL' },

    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
})
export class SharedModule {}
