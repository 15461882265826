/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { HandleSqsErrorRequest } from '../models/HandleSqsErrorRequest';
import type { Paginated_SqsError_ } from '../models/Paginated_SqsError_';
import type { SqsError } from '../models/SqsError';
import type { SqsErrorFilterConfig } from '../models/SqsErrorFilterConfig';
import type { SqsErrorSearchFilter } from '../models/SqsErrorSearchFilter';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class SqsErrorHandlingService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @deprecated
     * @param requestBody
     * @returns any Ok
     * @throws ApiError
     */
    public listSqsErrors(
        requestBody: SqsErrorFilterConfig,
    ): Observable<Array<any>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/sqsErrorHandling/list',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @param last
     * @param limit
     * @returns Paginated_SqsError_ Ok
     * @throws ApiError
     */
    public searchSqsErrors(
        requestBody: SqsErrorSearchFilter,
        last?: string,
        limit?: number,
    ): Observable<Paginated_SqsError_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/sqsErrorHandling/search',
            query: {
                'last': last,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns SqsError Ok
     * @throws ApiError
     */
    public handleSqsErrorV1(
        requestBody: HandleSqsErrorRequest,
    ): Observable<SqsError> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/sqsErrorHandling/handle',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}