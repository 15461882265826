import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[appDetectScrollToEnd]',
})
export class DetectScrollToEndDirective implements AfterViewInit, OnDestroy {
  @Output() endReached = new EventEmitter<void>();

  private intersectionObserver?: IntersectionObserver;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.intersectionObserver = new IntersectionObserver(entries => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        this.endReached.emit();
      }
    });

    this.intersectionObserver.observe(this.el.nativeElement);
  }

  ngOnDestroy() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }
}
