import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Merchant } from 'app/api/generated';

export interface OnboardingState extends EntityState<Merchant, string>, ActiveState {
  last?: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'onboardings', idKey: 'merchantId' })
export class OnboardingStore extends EntityStore<OnboardingState, Merchant, string> {
  constructor() {
    super();
  }
}
