/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { IntegrationProfileBase } from '../models/IntegrationProfileBase';
import type { Pick_IntegrationProfileBase_value_ } from '../models/Pick_IntegrationProfileBase_value_';
import type { ProfileDefaultKey } from '../models/ProfileDefaultKey';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class IntegrationProfileDefaultService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param merchantId
     * @param integrationProfileId
     * @returns IntegrationProfileBase Ok
     * @throws ApiError
     */
    public list(
        merchantId: string,
        integrationProfileId: string,
    ): Observable<Array<IntegrationProfileBase>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/merchants/{merchantId}/integrationProfiles/{integrationProfileId}/defaults',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
            },
        });
    }

    /**
     * @param merchantId
     * @param integrationProfileId
     * @param key
     * @returns IntegrationProfileBase Ok
     * @throws ApiError
     */
    public get(
        merchantId: string,
        integrationProfileId: string,
        key: ProfileDefaultKey,
    ): Observable<IntegrationProfileBase> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/merchants/{merchantId}/integrationProfiles/{integrationProfileId}/defaults/{key}',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
                'key': key,
            },
        });
    }

    /**
     * @param merchantId
     * @param integrationProfileId
     * @param key
     * @param requestBody
     * @returns IntegrationProfileBase Ok
     * @throws ApiError
     */
    public update(
        merchantId: string,
        integrationProfileId: string,
        key: ProfileDefaultKey,
        requestBody: Pick_IntegrationProfileBase_value_,
    ): Observable<IntegrationProfileBase> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/v1/merchants/{merchantId}/integrationProfiles/{integrationProfileId}/defaults/{key}',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}